/**
*
* @file images.js
* @name Late Loading Images
* @todo Add to Tutorial Doc
*
* @desc Quick and very simple ways to load images late in the load process to improve performance,
* and to give some control over image sizes. See comments for more information.
*/

/**

DBS custom image handling for mobile or where we need multiple sized images.

	Typical use case summary:

		1. Images below the fold: unveil.js / lazyload (img tag)
		2. Images above the fold where we need to provide alternative sizes: "lateload" class
		3. Images not visible initially, "lateload2" class

	Note: jquery.unveil.js is a separate / unrelated plugin for lazyloading.
	Unveil is the best choice in most cases for below the fold images. Unveil
	does not use classes, but checks all <img> tags. Unveil can handle retina
	or regular images.

	Unveil Example: <img src="<?php echo $path_to_trans_image . '/blank.gif' ;?>" data-src="<?php echo $path . '/example.png'?>" alt="" />

	Otherwise, dbs.late_load() is the primative DBS image loader, typically
	called on lateload* classes during ready or load events (see below), but
	can be called directly. "Lateloading" functionality is a custom DBS
	feature.

	NOTE: the	'_run_class' param must be specified. Typically, this is 'lateload'
	or 'lateload2'.

	In both the DBS custom "lateload*" cases below, image references simply need to be in a span like:

	   <span class="lateload2" data-src="image.png" data-thumb-src="blah" data-alt="text" data-min-width="769"></span>.

	An img element will be created from this span, using the embedded
	criteria. Two size images are possible with lateloading. Typically the
	smaller image is the 'thumbnail' size. The break between the 2 image
	sizes can optionally be specified using data-min-width attr. The default
	is 481.

	lateload class: image tags are created during ready event
	lateload2 class: image tags are created during the load event, most commonly used.

	TODO: should check orientation first.
	TODO: integrate more/better dbs custom stuff with unveil.js
	TODO: handle retina better


*/
dbs.late_load = function( callback ) {
	var width = jQuery( window ).width();
	jQuery( '.lateload').each( function() {
		if ( jQuery( this ).hasClass( "nophone" ) && dbs.device.is_phone ){ return;} // skip this altogether if smartphone
		if ( jQuery( this ).hasClass( "nomobile" ) && dbs.device.is_mobile ){ return;} // skip this altogether if phone or tablet

        // attributes may be encoded
        if ( jQuery( this ).attr( 'data-attrs' ) ){ data_attrs = decodeURIComponent ( jQuery( this ).attr( 'data-attrs' ) );}

        var min_width = 481, _class = '';

        // add custom class if we have it.
		if ( jQuery( this ).attr( 'data-class' ) ){
			_class = ' class="' + jQuery( this ).attr( 'data-class' ) + '"';
		}

		if ( jQuery( this ).attr( 'data-min-width' ) ){
			min_width = jQuery( this ).attr( 'data-min-width' );
		}

		// if we have an alternate "thumb" src, use that for small devices.
		if ( width > min_width || ! jQuery( this ).attr( 'data-thumb-src' ) ) {
			jQuery( this ).parent().append( '<img ' + _class + ' src="' + jQuery( this ).attr( "data-src" ) + '" alt="" />' );
		} else {
			jQuery( this ).parent().append( '<img ' + _class + ' src="' + jQuery( this ).attr( "data-thumb-src" ) + '" alt="" />' );
		}
        if( typeof(callback) == "function" ) { callback(); }
	});
};

// lazyload images via jQuery unveil plugin, see php helper in functions.php
dbs.lazy_load = function( threshold, callback ){
	// if we have 'nophone' or 'nomobile' classes, get rid of those elements first.
	if ( dbs.device.is_phone ) jQuery( "img" ).remove( ".nophone" );
	if ( dbs.device.is_mobile ) jQuery( "img" ).remove( ".nomobile" );
	threshold = threshold || 200;
    jQuery( 'img' ).unveil( threshold );
    if( typeof(callback) == "function" ) { callback(); }
}
