/**
* @file scripts.js
*
* @name DBS Scripts File
* @author dbsinteractive
*
* This file should contain any js scripts you want to add site wide. As of Oct
* 2014 this file is typically combined with other js libs into global.min.js for
* all theme based site wide scripts.
*
*/

"use strict";

/**
 * Remove 300ms delay for mobile browsers.
 * @author DBS
 * @since 2014-11-10
 */
//FastClick.attach( document.body );
////////////////////////////////

function setCookie(key, value) {
	var expires = new Date();
	expires.setTime( expires.getTime() + ( 1 * 60 * 60 * 1000 ) );
	document.cookie = key + '=' + value + ';path=/' + ';expires=' + expires.toUTCString();
}

function getCookie(key) {
	var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
	return keyValue ? keyValue[2] : null;
}

// IE8 ployfill for GetComputed Style (for Responsive Script below)
if ( ! window.getComputedStyle ) {
	window.getComputedStyle = function(el) {
		this.el = el;

		this.getPropertyValue = function(prop) {

			var re = /(\-([a-z]){1})/g;

			if (prop === 'float'){
				prop = 'styleFloat';
			}

			if (re.test(prop)) {
				prop = prop.replace(re, function () {
					return arguments[2].toUpperCase();
				});
			}
			return el.currentStyle[prop] ? el.currentStyle[prop] : null;
		};
		return this;
	};
}


function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

/**
*
* @file scripts.js
* @name iOS Orientation Zoom Fix
* @author @scottjehl, rebound by @wilto
* @license MIT
*
* @desc A fix for the iOS orientationchange zoom bug.
*/
( function( w ) {
	// This fix addresses an iOS bug, so return early if the UA claims it's something else.
	if( !( /iPhone|iPad|iPod/.test( navigator.platform ) && navigator.userAgent.indexOf( "AppleWebKit" ) > -1 ) ){ return; }
	var doc = w.document;
	if( !doc.querySelector ){ return; }
	var meta = doc.querySelector( "meta[name=viewport]" ),
		initialContent = meta && meta.getAttribute( "content" ),
		disabledZoom = initialContent + ",maximum-scale=1",
		enabledZoom = initialContent + ",maximum-scale=10",
		enabled = true,
		x, y, z, aig;
	if( !meta ){ return; }
	function restoreZoom(){
		meta.setAttribute( "content", enabledZoom );
		enabled = true; }
	function disableZoom(){
		meta.setAttribute( "content", disabledZoom );
		enabled = false; }
	function checkTilt( e ){
		aig = e.accelerationIncludingGravity;
		x = Math.abs( aig.x );
		y = Math.abs( aig.y );
		z = Math.abs( aig.z );
		// If portrait orientation and in one of the danger zones
		if( !w.orientation && ( x > 7 || ( ( z > 6 && y < 8 || z < 8 && y > 6 ) && x > 5 ) ) ){
			if( enabled ){ disableZoom(); } }
		else if( !enabled ){ restoreZoom(); } }
	w.addEventListener( "orientationchange", restoreZoom, false );
	w.addEventListener( "devicemotion", checkTilt, false );
})( window );


/**
 * DOCUMENT READY
 *
 * This fires when the DOM has been loaded.
 * Fires first.
 * DO NOT DUPLICATE THE READY EVENT. Use the ready event here.
 */
jQuery( document ).ready( function(){

	var searchTogglelink = jQuery('.icon--search-white');
	var searchbar = jQuery('.searchbar');

	jQuery(function($) {
		$('a[href*="#"]:not([href="#"])').click(function( event ) {
			var padding = 125;
			if( ($(this).hasClass('tab__link') === true) || ($(this).attr('href').indexOf('#tab') > -1) ) {
				var titleHeight = jQuery('.content__title').height();
				var height = jQuery('.tab-menu').height();
				padding = 180 + titleHeight + height;
			}

			// this code causes thickbox to barf 2016-09-09
			if ( $(this).hasClass( 'thickbox' ) ) {
				return;
			}

			if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
				event.preventDefault()
				var target = $(this.hash);
				target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
				if (target.length) {
					$('html, body').stop().animate({
						scrollTop: target.offset().top - padding
					}, 750);
						window.setTimeout(function() {
						$('html, body').stop().animate({
						scrollTop: target.offset().top - padding
						}, 750);
					}, 250);
				}
			}
		});

		// Simulate Click whenever it detects a tab__link name in the URL.2016-09-15
		var siteDomain = document.URL;
		var tabURI = 'ministries/today/';
		var tabID = siteDomain.substring( siteDomain.lastIndexOf( tabURI ) + tabURI.length );
		if ( tabID.match( '^#' ) && $( 'a' ).attr( 'href' ) === tabID ) { $( 'a[href="' + tabID + '"]' ).trigger( 'click' ); }
	});




	jQuery(function($) {
		window.setTimeout(function() {
			if(location.hash.length !== 0) {
				$('html, body').animate({
					scrollTop: window.scrollY - 125
				}, 1000);
			}
		}, 1);
	});

	jQuery('a.icon--search-white').on('click', function(e) {
		e.preventDefault();

		searchbar.slideToggle(300, function() {
		// callback after search bar animation
		jQuery('.header #search-box').find('input.gsc-input').focus();
		});

	});

	//Hide the menu when clicked off
	jQuery('html').click(function() {
		if ( jQuery(window).width() >= 860 ) {
			jQuery('.searchbar').slideUp();
		}
	});

	jQuery('body').click(function(event) {
		if( !jQuery(event.target).is('.dropdown__button') ) {
			jQuery('.dropdown__menu.show-menu').removeClass("show-menu");
		}
	});

	//Don't include button or menu in 'html' click function
	jQuery('.searchbar, .icon--search-white, .menu-button').click(function(event) {
		event.stopPropagation();
	});

	jQuery('.menu-button').click( function() {
		searchbar.slideToggle(300, function() {
		// callback after search bar animation
		jQuery('input[type="search"].search').focus();
		});
		jQuery('.inner-header').toggleClass('inner-header--menu-active');
		jQuery('.mobile-menu').slideToggle('fast');	// apply the toggle to the ul
		jQuery('.mobile-menu').toggleClass('mobile-menu--enable');
	});

	if ( !jQuery( '.featured-content__slide' ).hasClass( 'slide_2' ) ) {
		jQuery( '.featured-content__slides' ).removeClass( 'featured-content__slides' );
		jQuery( '.featured-content__wrapper > div' ).addClass( 'featured-content-static__slides' );
	}


	jQuery(".dropdown__button").click(function() {
		var button, menu;
		button = jQuery(this);
		menu = button.siblings(".dropdown__menu");
		jQuery(".dropdown__menu.show-menu").removeClass("show-menu");
		menu.toggleClass("show-menu");
		menu.children("li").click(function() {
			menu.removeClass("show-menu");
			//button.html(jQuery(this).html());
		});
	});

	var fixMenu = debounce( function() {
		if (jQuery(window).scrollTop() > 42) {
			jQuery('.header').addClass('header--fixed');
			jQuery('#container').addClass('container--fixed');
		}
		else {
			jQuery('.header').removeClass('header--fixed');
			jQuery('#container').removeClass('container--fixed');
		}
	}, 1 );

	jQuery(window).bind('scroll', fixMenu);

	jQuery('.mobile-menu .menu-item-has-children>a').bind('click', function(e){
		jQuery(this).parent().find('.sub-menu').slideToggle('fast');	// apply the toggle to the ul
		jQuery(this).parent().toggleClass('sub-menu--expanded');
		e.preventDefault();
	});

	var mobileMenu = debounce( function() {
		if ( jQuery(window).width() >= 860 ) {
			jQuery('.mobile-menu').slideUp('fast');
			jQuery('.mobile-menu').removeClass('mobile-menu--enable');

			jQuery('.searchbar').slideUp();
			searchbar.removeClass('searchbar--mobile');
			jQuery('.inner-header').removeClass('inner-header--menu-active');
		} else {
			searchbar.addClass('searchbar--mobile');
		}
	}, 250);

	jQuery(window).resize(mobileMenu);

	var shareIcons = debounce( function() {
		if( jQuery('.social-share')[0] ) {
			var shareScrollStart = jQuery('.content').offset().top - 60;
			var shareScrollEnd = jQuery('.content').height() ;

			if( jQuery(document).scrollTop() >= shareScrollStart ) {
				if( jQuery(document).scrollTop() >= shareScrollEnd ) {
					jQuery('.social-share').removeClass('social-share--fixed');
					jQuery('.social-share').addClass('social-share--position-absolute');
				} else {
					jQuery('.social-share').removeClass('social-share--position-absolute');
					jQuery('.social-share').addClass('social-share--fixed');
				}
			} else {
				jQuery('.social-share').removeClass('social-share--position-absolute');
				jQuery('.social-share').removeClass('social-share--fixed');
			}
		}

	}, 5 );
	jQuery(window).bind('scroll', shareIcons);

});

/**
 * WINDOW LOAD
 *
 * This fires when everything is finished loading.
 * Fires last.
 * DO NOT DUPLICATE THE LOAD EVENT. Use the load event here.
 */
// DBS_cleanup: Replaces load function.
window.addEventListener( 'load', function() {
  dbs.late_load();
  dbs.lazy_load();

  // Force lazyloading 2015-05-31
  setTimeout( function() {
    jQuery( window ).trigger("scroll").trigger( "resize" );
  }, 250 );

  jQuery( '.img-button-inner' ).closest( 'p' ).addClass( 'img-button-wrap' );

  // 2016-05-15, force opening external links in new tab.
  if ( ! jQuery( 'a' ).attr( 'target' ) ) {
    jQuery( 'a[href^=http]:not([href*="' + dbs.http_host + '"])' ).attr( 'target', '_blank' );
  }

  // 2017-11-21, Hide "Contact Us" button on the contact-us page
  if ( window.location.href.indexOf("/who-we-are/contact-us/") > -1 ) {
    jQuery(".footer-verse__button").hide();
  };

	jQuery( ".skiptranslate" ).addClass( "hide-print" );
    
    jQuery('.home-slider__slides').lightSlider({
        item: 1,
        auto: true,
        loop: true,
        speed: 600,
        pause: 6000
    });

	jQuery('.featured-content__slides').lightSlider({
		item: 1,
		auto: true,
		loop: true,
		speed: 600,
		pause: 6000
	});
});

// search child dom elements for a[href], and follow that on click events 2015-11-03
jQuery( ".linkclicker" ).click( function(e) {
  e.preventDefault();
  if ( jQuery( this ).find( "a:first" ).attr( "target" ) ) {
    // new tab
    window.open( jQuery( this ).find( "a:first" ).attr( "href" ) );
  } else {
    // same tab
    window.location = jQuery( this ).find( "a:first" ).attr( "href" );
  }
});

// Alert donate popup
jQuery('.fund-popup__close').click( function() {
  jQuery('.fund-popup').hide();
  setCookie( 'fund_popup', jQuery('#fund_id').val()  );
});

jQuery( '.fund-popup__button' ).click( function() {
  jQuery( '.fund-popup' ).hide();
  setCookie( 'fund_popup', jQuery('#fund_id').val() );
} );

// image sizing issue in Stories, kerick 2016-12-20
// UPDATED: Added loop so to assign the individual widths to their respective images. 2017-03-03
// var max_width = 'max-width: ' + jQuery( '[id^=attachment_] img' ).attr( 'width' ) + 'px';
// jQuery( '[id^=attachment_]' ).attr( 'style', max_width );
// jQuery( '[id^=attachment_] img' ).attr( 'width', '100%' ).attr( 'height', '100%' );
var attachments = jQuery( '[id^=attachment_]' );
jQuery.each( attachments, function( index, attachment ) {
	var max_width = 'max-width:' + jQuery( attachment ).find( 'img' ).attr( 'width' ) + 'px;';
	jQuery( attachment ).attr( 'style', max_width );
	jQuery( attachment ).find( 'img' ).attr( 'width', '100%' ).attr( 'height', '100%' );
} );

// 2016-09-13, email sharing feature
function social_share_email () {
	if ( jQuery( window ).width() < 480 ) { // mobile
		jQuery( ".tbox-email" ).attr( "href", "#TB_inline?width=275&height=500&inlineId=email-this-page");
        jQuery( ".ginput_counter" ).remove(); jQuery( ".gform_footer" ).css ({ "padding": "0", "margin" : "0" });
	}
}

jQuery( ".tbox-email" ).click( function() {
	setTimeout( function() {
		jQuery( "#TB_window form input:first" ).focus();
	}, 400 );
});
